export enum BoardType {
	TO_DO = 'TO DO',
	DOING = 'DOING',
	DONE = 'DONE'
}

export type ActionPlan = {
	id: string;
	title: string;
	description?: string;
	deadline: Date;
	board: BoardType;
	lexo_rank?: string;
	file_id: string;
	user_id?: string;
	responsible_user_id?: string;
	step_id?: string;
	custom_report_step_key_setting_id?: string;
	custom_report_sub_step_key_id?: string;
	is_active: boolean;
	completed_at?: Date;
	created_at: Date;
	updated_at: Date;
};
