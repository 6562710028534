import { message } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import Api from '@/services/api';
import { AxiosErrorResponse, BaseContext, BaseReportsPaginated } from '@/types';
import { QUERY_KEYS } from '@/utils/query-keys';

type DeleteErgonomicToolReportDTO = BaseContext & {
	toolURL: string;
	result_id?: string;
};

async function deleteReport(params: DeleteErgonomicToolReportDTO): Promise<BaseReportsPaginated> {
	const url = params.toolURL;
	const { data } = await Api.delete<BaseReportsPaginated>(url, {
		params
	});
	return data;
}

export const useDeleteErgonomicToolReport = () => {
	const queryClient = useQueryClient();
	return useMutation<BaseReportsPaginated, AxiosErrorResponse, DeleteErgonomicToolReportDTO>(
		(payload) => deleteReport(payload),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([QUERY_KEYS.GET_REBA_REPORTS_PAGINATED]);
				queryClient.invalidateQueries([QUERY_KEYS.GET_NIOSH_REPORTS_PAGINATED]);
				queryClient.invalidateQueries([QUERY_KEYS.GET_KIM_PP_REPORTS_PAGINATED]);
				queryClient.invalidateQueries([QUERY_KEYS.GET_KIM_MHO_REPORTS_PAGINATED]);
				queryClient.invalidateQueries([QUERY_KEYS.GET_ANGLE_TIME_REPORTS_PAGINATED]);
				queryClient.invalidateQueries([QUERY_KEYS.GET_CUSTOM_REPORT_RESULT_PAGINATED]);
				queryClient.invalidateQueries([QUERY_KEYS.GET_STRAIN_INDEX_REPORTS_PAGINATED]);
				queryClient.invalidateQueries([QUERY_KEYS.GET_LIBERTY_MUTUAL_REPORTS_PAGINATED]);
				message.success(I18n.get('Report deleted successfully'));
			}
		}
	);
};
