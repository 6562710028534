import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Button, Modal } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, MenuOutlined } from '@ant-design/icons';

import { DownloadVideoIcon } from '../../../views/ListReport/components';
import { useBulkDownloadVideos } from '@/hooks/useBulkDownloadVideos';
import { useBulkDeleteFiles } from '@/hooks/useBulkDeleteFiles';
import { useApplicationContext } from '@/context/Application';
import { FilesMoreDetailsSVG } from '@/components/Icons';
import { EditFileModal } from './List/EditFileModal';
import { Title } from '@/components/Typography';
import { DeleteFilesRequest } from '@/hooks';
import Can from '@/components/Can';
import { Filter } from './Filter';
import { List } from './List';
import * as S from './styles';

export function Files() {
	const history = useHistory();
	const { search } = useLocation();
	const { organization, company } = useApplicationContext();
	const searchParams = new URLSearchParams(search);
	const isList = searchParams.get('list') !== 'false';

	const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
	const [isBulkEditFilesModalOpen, setIsBulkEditFilesModalOpen] = useState<boolean>(false);

	const { mutateAsync: deleteFiles } = useBulkDeleteFiles();
	const { mutateAsync: downloadVideos } = useBulkDownloadVideos();

	function handleChangeListType(value: boolean): void {
		if (value === isList) {
			return;
		}
		searchParams.set('list', `${value}`);
		history.push({
			search: searchParams.toString()
		});
	}

	function handleSelectRowKeys(selectedRows: string[]): void {
		setSelectedRowKeys(selectedRows);
	}

	async function handleBulkEditHierarchy() {
		setIsBulkEditFilesModalOpen(!isBulkEditFilesModalOpen);
	}

	async function handleBulkDownloadVideo() {
		await downloadVideos({
			company_id: company?.id,
			file_ids: selectedRowKeys,
			organization_id: organization?.id
		});
	}

	async function handleBulkDeleteFiles() {
		Modal.confirm({
			title: I18n.get(`Heads up! Want to delete those files?`),
			icon: <ExclamationCircleOutlined />,
			okText: I18n.get('Confirm'),
			cancelText: I18n.get('Cancel'),
			okType: 'danger',
			onOk: async () => {
				const body: DeleteFilesRequest = {
					company_id: company?.id,
					file_ids: selectedRowKeys,
					organization_id: organization?.id
				};

				await deleteFiles(body);
			}
		});
	}

	function showBulkActions(selectedRowKeys: string[]): JSX.Element | undefined {
		if (selectedRowKeys.length > 1) {
			const permissionRole = Can('delete', 'upload');
			return (
				<Col style={{ marginRight: '16px' }}>
					<S.GeneralActions align="middle" justify="space-between" gutter={[10, 0]}>
						<Col>
							<Button type="link" icon={<EditOutlined />} onClick={handleBulkEditHierarchy} />
						</Col>
						<Col>
							<Button
								type="link"
								onClick={handleBulkDownloadVideo}
								icon={<DownloadVideoIcon width={22} height={22} />}
							/>
						</Col>
						<Col>
							<Button
								danger
								type="link"
								icon={<DeleteOutlined />}
								disabled={!permissionRole}
								onClick={handleBulkDeleteFiles}
							/>
						</Col>
					</S.GeneralActions>
				</Col>
			);
		}
	}

	return (
		<Row gutter={[0, 8]}>
			<Col span={24}>
				<Row justify="space-around" align="middle">
					<Col span={12}>
						<Title level={3} style={{ marginBottom: 0 }}>
							Files
						</Title>
					</Col>
					<Col span={12}>
						<Row justify="end" align="middle" gutter={[8, 0]}>
							{showBulkActions(selectedRowKeys)}
							<Col>
								<S.ButtonList
									size="large"
									type="default"
									isList={isList}
									icon={<MenuOutlined />}
									onClick={() => handleChangeListType(true)}
								/>
							</Col>
							<Col>
								<S.ButtonMoreDetails
									size="large"
									type="default"
									isList={isList}
									icon={<FilesMoreDetailsSVG />}
									onClick={() => handleChangeListType(false)}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<Filter />
			</Col>
			<Col span={24}>
				<List onSelectRowKeys={handleSelectRowKeys} selectedRowKeys={selectedRowKeys} isList={isList} />
			</Col>
			{isBulkEditFilesModalOpen && (
				<EditFileModal
					centered
					width={550}
					footer={null}
					filesIds={selectedRowKeys}
					open={isBulkEditFilesModalOpen}
					onCancel={handleBulkEditHierarchy}
				/>
			)}
		</Row>
	);
}
