import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CustomFormItem, TextInput } from '../../styles';
import { FieldsProps } from '../types';

const { useFormInstance, useWatch } = Form;

export function Nit({ name, required = false }: Readonly<FieldsProps>) {
	const form = useFormInstance();

	const formValue = useWatch(name);

	const handleInputChange = (e: any) => {
		let value = e.target.value;

		value = value.replace(/\D/g, '');
		value = applyMask(value);

		form.setFieldValue(name, value);
	};

	function applyMask(value: any) {
		if (value.length > 3) value = value.slice(0, 3) + '.' + value.slice(3);
		if (value.length > 7) value = value.slice(0, 7) + '.' + value.slice(7);
		if (value.length > 11) value = value.slice(0, 11) + '-' + value.slice(11);
		return value;
	}

	function nitValidator() {
		if (required && !formValue) {
			return Promise.reject(I18n.get('Enter a NIT'));
		}

		if (formValue.length < 13) {
			return Promise.reject(I18n.get('Enter a valid NIT'));
		}

		return Promise.resolve();
	}

	return (
		<CustomFormItem name={name} label="NIT" labelCol={{ span: 24 }} rules={[{ required, validator: nitValidator }]}>
			<TextInput maxLength={13} onChange={handleInputChange} placeholder={I18n.get('000.000.000-0')} />
		</CustomFormItem>
	);
}
