import React from 'react';
import moment from 'moment';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useHistory, useLocation } from 'react-router-dom';

import { RatingTag } from './RatingTag';
import { Text } from '@/components/Typography';
import { ActionsColumn } from '../ActionsColumn';
import { PaginationCustom } from '@/components/Pagination';
import { PaginatedData, type BaseReportsPaginatedResponse, type ScoreResult } from '@/types';
import { ErgonomicTools } from '../../types';

type ReportNameMapper = {
	[key in ErgonomicTools]: string;
};

export interface BaseProps extends BaseReportsPaginatedResponse {}

interface ReportListTableProps<T extends BaseProps> {
	page: number;
	total: number;
	limit: number;
	reportId: string;
	data?: PaginatedData<T>;
	reportName: ErgonomicTools;
}

export function ReportListTable<T extends BaseProps>({
	data,
	page,
	total,
	limit,
	reportId,
	reportName
}: Readonly<ReportListTableProps<T>>) {
	const reportNameToTitleMapper: ReportNameMapper = {
		niosh: 'Risk',
		'angle-time': '',
		reba: 'REBA Score',
		'kim-pp': 'KIM PP score',
		'kim-mho': 'KIM MHO score',
		rula: 'Exposure average',
		'personalised-report': 'Worst score',
		'preliminary-ergonomic-analysis': 'Worst score',
		'strain-index': 'Revised Strain Index',
		'liberty-mutual': 'Percentile (Man | Woman)'
	};

	const history = useHistory();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

	function isConsolidateReport({ text, result }: ScoreResult): boolean {
		return !!text && !!result;
	}

	const columns: ColumnsType<T> = [
		{
			key: 1,
			align: 'center',
			dataIndex: 'report_name',
			title: <Text>Name</Text>,
			sorter: (a: any, b) => a?.report_name.localeCompare(b?.report_name)
		},
		{
			key: 2,
			align: 'center',
			dataIndex: 'created_at',
			title: <Text>Date</Text>,
			render: (created_at: Date) => moment(created_at).format('L'),
			sorter: (a, b) => moment(a.created_at).valueOf() - moment(b.created_at).valueOf()
		},
		{
			key: 3,
			align: 'center',
			dataIndex: 'evaluator',
			title: <Text>Evaluator</Text>,
			sorter: (a, b) => a.evaluator.localeCompare(b.evaluator)
		},
		{
			key: 5,
			align: 'center',
			dataIndex: 'workstation',
			title: <Text>Workstation</Text>,
			sorter: (a, b) => a.workstation.localeCompare(b.workstation)
		},
		{
			key: 6,
			align: 'center',
			dataIndex: 'score',
			title: <Text>{reportNameToTitleMapper[reportName] ?? 'Rating'}</Text>,
			sorter: (a, b) => (a.score?.result ?? 0) - (b.score?.result ?? 0),
			render: (score: ScoreResult) => {
				return isConsolidateReport(score) ? <RatingTag score={score} reportName={reportName} /> : '-';
			}
		},
		{
			key: 7,
			width: '15%',
			align: 'center',
			dataIndex: 'id',
			title: <Text>Actions</Text>,
			render: (_id, data) => <ActionsColumn reportId={reportId} data={data} reportName={reportName} />
		}
	];

	if (reportName === ErgonomicTools['angle-time']) {
		const index = columns.findIndex(({ key }) => key === 6);
		columns[index] = {
			key: 4,
			align: 'center',
			dataIndex: 'sector',
			title: <Text>Sector</Text>,
			sorter: (a, b) => a.sector.localeCompare(b.sector)
		};
		columns.sort((a, b) => Number(a.key) - Number(b.key));
	}

	function handleRowsPerPageChange(rows: number): void {
		searchParams.set('page', `${1}`);
		searchParams.set('rows', `${rows ?? 10}`);
		history.push({ search: searchParams.toString() });
	}

	function handlePageChange(page: number): void {
		searchParams.set('page', `${page ?? 1}`);
		history.push({ search: searchParams.toString() });
	}

	return (
		<>
			<Table rowKey="id" dataSource={data?.rows} columns={columns} pagination={false} />
			<PaginationCustom
				total={total}
				pageSize={limit}
				defaultCurrent={page}
				onChange={handlePageChange}
				onRowsPerPageChange={handleRowsPerPageChange}
			/>
		</>
	);
}
