import { Moment } from 'moment';

import { PaginatedData, BaseReportsPaginatedResponse } from '@/types';

//TODO remove this enum when aep turns into a custom report
export enum ErgonomicTools {
	'reba' = 'reba',
	'rula' = 'rula',
	'niosh' = 'niosh',
	'kim-pp' = 'kim-pp',
	'kim-mho' = 'kim-mho',
	'angle-time' = 'angle-time',
	'strain-index' = 'strain-index',
	'liberty-mutual' = 'liberty-mutual',
	'personalised-report' = 'personalised-report',
	'preliminary-ergonomic-analysis' = 'preliminary-ergonomic-analysis'
}

export type ReportsListUrlParams = {
	report_name: ErgonomicTools;
	custom_report_id: string;
};

export type ReportInfo = {
	title: string;
	data?: PaginatedData<BaseReportsPaginatedResponse>;
};

export type ReportNameToReportInfoMapper = {
	[key in ErgonomicTools]: ReportInfo | undefined;
};

export type FormFields = {
	sector_id: string;
	company_id: string;
	cellule_id: string;
	evaluator_id: string;
	original_name: string;
	created_at?: Moment[];
	workstation_id: string;
	organization_id: string;
};

export type FilterRequest = {
	page?: string;
	rows?: string;
	sector_id: string;
	end_date?: string;
	company_id: string;
	cellule_id: string;
	start_date?: string;
	evaluator_id: string;
	original_name: string;
	workstation_id: string;
	activity_id?: string;
	organization_id: string;
};

export type RenderTableParams = {
	page: number;
	limit: number;
	total: number;
	loading: boolean;
	report_name: ErgonomicTools;
	reportNameToInfoMapper: ReportNameToReportInfoMapper;
};
