import React from 'react';
import { Row, Modal } from 'antd';
import { I18n } from '@aws-amplify/core';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { Inline } from './Inline';
import { BaseProps } from '../ReportListTable';
import { HamburgerMenu } from './HamburgerMenu';
import { useDeleteErgonomicToolReport, useDownloadErgonomicToolPDF, useDownloadVideo } from '@/hooks';
import { ErgonomicTools } from '../../types';
import * as S from './styles';

interface ActionsColumnProps {
	data: BaseProps;
	reportId: string;
	reportName: ErgonomicTools;
}

export function ActionsColumn({ data, reportName, reportId }: Readonly<ActionsColumnProps>) {
	const organization_id = data?.file?.organization_id ?? data?.company?.organization_id;
	const company_id = data?.file?.company_id ?? data?.company?.id;

	const { mutateAsync: downloadVideo } = useDownloadVideo();
	const { mutateAsync: downloadPDF } = useDownloadErgonomicToolPDF();
	const { mutateAsync: deleteReport } = useDeleteErgonomicToolReport();

	async function handleDownloadVideoClick(event: React.MouseEvent<HTMLElement, MouseEvent>): Promise<void> {
		event.stopPropagation();
		event.preventDefault();

		await downloadVideo({
			file_id: data?.file?.id,
			company_id: data?.file?.company_id,
			original_name: data?.file?.original_name,
			organization_id: data?.file?.organization_id
		});
	}

	async function handleDownloadPDFClick(event: React.MouseEvent<HTMLElement, MouseEvent>): Promise<void> {
		event.stopPropagation();
		event.preventDefault();

		const browserLanguage = window.navigator.language ?? 'en-US';

		let url = `ergonomic-tool/${reportName}`;

		if (reportName === ErgonomicTools['personalised-report']) {
			url = `custom-report/result`;
		}

		if (reportName === ErgonomicTools['preliminary-ergonomic-analysis']) {
			url = `ergonomic-tool/preliminary-analysis`;
		}

		await downloadPDF({
			company_id,
			toolURL: url,
			id: data?.id,
			organization_id,
			report_id: reportId,
			file_id: data?.file?.id,
			locale: browserLanguage,
			custom_report_result_id: data?.id
		});
	}

	async function handleDeleteReport(event: React.MouseEvent<HTMLElement, MouseEvent>): Promise<void> {
		event.stopPropagation();
		event.preventDefault();

		const url =
			reportName === ErgonomicTools['personalised-report']
				? '/custom-report/result/'
				: `/ergonomic-tool/${reportName}/`;

		Modal.confirm({
			title: `${I18n.get('Warning!')}`,
			okText: `${I18n.get('Confirm')}`,
			icon: <ExclamationCircleOutlined />,
			cancelText: `${I18n.get('Cancel')}`,
			content: `${I18n.get('Want to delete this report?')}`,
			okType: 'danger',
			onOk: async () => {
				await deleteReport({
					company_id,
					toolURL: url,
					organization_id,
					result_id: data?.id
				});
			}
		});
	}

	function handleEditReportClick(event: React.MouseEvent<HTMLElement, MouseEvent>): void {
		event.stopPropagation();
		event.preventDefault();

		const id = data?.file_id ?? data?.file_id ?? data?.id;
		let url = `/reporting/${reportName}/${id}?`;

		if (reportName === ErgonomicTools['personalised-report']) {
			url = `/reporting/${reportName}/${reportId}/${data?.file?.id || ''}?id=${data?.id}&`;
		}

		const win = window.open(`${url}organization_id=${organization_id}&company_id=${company_id}`, '_blank');
		win?.focus();
	}

	return (
		<S.ActionsColumnContainer xs={24}>
			<Row className="inline">
				<Inline
					hasFile={!!data.file?.id}
					disableDelete={reportName === ErgonomicTools['preliminary-ergonomic-analysis']}
					disabled={!data.is_finished}
					onDeleteReport={handleDeleteReport}
					onEditReportClick={handleEditReportClick}
					onDownloadPDFClick={handleDownloadPDFClick}
					onDownloadVideoClick={handleDownloadVideoClick}
				/>
			</Row>
			<Row className="hamburger-menu">
				<HamburgerMenu
					hasFile={!!data.file?.id}
					disabled={!data.is_finished}
					onDeleteReport={handleDeleteReport}
					onEditReportClick={handleEditReportClick}
					onDownloadPDFClick={handleDownloadPDFClick}
					onDownloadVideoClick={handleDownloadVideoClick}
				/>
			</Row>
		</S.ActionsColumnContainer>
	);
}
