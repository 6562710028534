export enum ToolsNames {
	rula = 'rula',
	reba = 'reba',
	niosh = 'niosh',
	kim_pp = 'kim_pp',
	kim_mho = 'kim_mho',
	angle_time = 'angle_time',
	strain_index = 'strain_index',
	liberty_mutual = 'liberty_mutual'
}

export enum ErgonomicTools {
	'rula' = 'rula',
	'reba' = 'reba',
	'niosh' = 'niosh',
	'kim-pp' = 'kim-pp',
	'kim-mho' = 'kim-mho',
	'angle-time' = 'angle-time',
	'strain-index' = 'strain-index',
	'liberty-mutual' = 'liberty-mutual',
	'personalised-report' = 'personalised-report'
}

export type ErgonomicTool = {
	id: string;
	name: ToolsNames;
	description: ErgonomicTools;
	title: string;
	subtitle: string;
	category_id: string;
	created_at: Date;
	updated_at: Date;
	deleted_at: Date | null;
};
