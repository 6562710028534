import React from 'react';
import { Row } from 'antd';

import { useGetOrganizationUsers } from '../../../hooks/useGetOrganizationUsers';
import { useContractPlansContext } from '../../../context';
import { Content } from './Content';
import { Header } from './Header';
import * as S from './styles';
import { ReactComponent as NotFoundSvg } from '@/assets/svg/not_found.svg';

type GetParams = {
	organization_id?: string;
	company_id?: string;
};

export function Table() {
	const { queryUrl } = useContractPlansContext();

	function getParameters(): GetParams {
		const organization_id = queryUrl?.get('organization_id');
		const company_id = queryUrl?.get('company_id');
		const type = queryUrl?.get('type');

		if (type === 'company') {
			return { organization_id, company_id };
		}

		return { organization_id };
	}

	const { isLoading, data, isError } = useGetOrganizationUsers(getParameters());

	return (
		<S.Container span={24}>
			<Row gutter={[0, 5]}>
				{data?.length ? (
					<>
						<Header />
						<Content isLoading={isLoading} data={data} isError={isError} />
					</>
				) : (
					<>
						<S.ColNotFound lg={24}>
							<S.IconNotFound component={NotFoundSvg} />
						</S.ColNotFound>
						<S.ColNotFound lg={24}>
							<p style={{ color: '#c8c8c8', marginTop: '-5px' }}>Não há dados</p>
						</S.ColNotFound>
					</>
				)}
			</Row>
		</S.Container>
	);
}
