import React, { useState } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { Col, Row, Tooltip } from 'antd';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import { ActionsWrapper, Label, PlayerRowContainer } from './styles';
import { ListWithoutPlayerProps } from '../ListWithoutPlayer';
import { riskCalc, riskLevels } from '@/utils/riskRange';
import { CustomModal } from '@/components/CustomModal';
import { Text, Title } from '@/components/Typography';
import { ActionsForFile } from '../ActionsForFile';
import { fileStatusMapper } from '@/utils/enum';
import { formatFileSize } from '@/utils/mask';
import { FilesListResponse } from '@/hooks';
import { Thumbnail } from './Thumbnail';
import { TagRisk } from '../../styles';
import { Player } from './Player';

interface ListWithPlayerProps extends ListWithoutPlayerProps {
	file: FilesListResponse;
	onSelectRowKey: (selectedRows: string[]) => void;
}

type ModalStatus = {
	isOpen: boolean;
	fileId?: string;
};

export function ListWithPlayer({
	file,
	onEditFile,
	onDeleteFile,
	selectedRows,
	onSelectRowKey,
	onDownloadVideo,
	onRedirectPreliminary,
	onShowReportOptionsReports
}: Readonly<ListWithPlayerProps>) {
	const [modalStatus, setModalStatus] = useState<ModalStatus>({
		isOpen: false,
		fileId: undefined
	});

	function handleShowModal(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
		event.preventDefault();
		event.stopPropagation();
		setModalStatus({
			isOpen: true,
			fileId: file.id
		});
	}

	function handleCancel() {
		setModalStatus({
			isOpen: false,
			fileId: undefined
		});
	}

	function handleSelectRow(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
		event.preventDefault();
		event.stopPropagation();
		const selectedRowsCopy = [...selectedRows];

		if (selectedRowsCopy.indexOf(file.id) >= 0) {
			selectedRowsCopy.splice(selectedRowsCopy.indexOf(file.id), 1);
		} else {
			selectedRowsCopy.push(file.id);
		}
		onSelectRowKey(selectedRowsCopy);
	}

	return (
		<Col key={file.id} span={24}>
			<PlayerRowContainer
				gutter={[0, 8]}
				onClick={handleSelectRow}
				$isRowSelected={selectedRows.some((id) => id === file.id)}
			>
				<Thumbnail file={file} onShowVideoModal={handleShowModal} />
				<Col lg={17} xxl={19} style={{ padding: '10px 0' }}>
					<Row gutter={[0, 15]} align="middle" style={{ height: '100%' }}>
						<Col span={24}>
							<Title level={5}>{file.original_name}</Title>
						</Col>
						<Col span={24}>
							<Row gutter={[0, 10]}>
								<Col lg={12} xxl={6}>
									<Row gutter={[0, 10]}>
										<Col span={24}>
											<Label>{I18n.get('Size')}:</Label>
											<Text>{formatFileSize(file.size)}</Text>
										</Col>
										<Col span={24}>
											<Label>{I18n.get('Company')}:</Label>
											<Text>{file.organization?.name}</Text>
										</Col>
									</Row>
								</Col>
								<Col lg={12} xxl={6}>
									<Row gutter={[0, 10]}>
										<Col span={24}>
											<Label>{I18n.get('Upload date')}:</Label>
											<Text>{moment(file.createdAt).format('L')}</Text>
										</Col>
										<Col span={24}>
											<Label>Industrial site:</Label>
											<Text>{file.company?.name}</Text>
										</Col>
									</Row>
								</Col>
								<Col lg={12} xxl={6}>
									<Row gutter={[0, 10]}>
										<Col span={24}>
											<Label>{I18n.get('Status')}:</Label>
											<Text>{I18n.get(fileStatusMapper[file.status])}</Text>
										</Col>
										<Col span={24}>
											<Label>{I18n.get('Sector')}:</Label>
											<Text>{file.sector?.name}</Text>
										</Col>
									</Row>
								</Col>
								<Col lg={12} xxl={6}>
									<Row gutter={[0, 10]}>
										<Col span={24}>
											<Label>{I18n.get('Rating')}:</Label>
											{file.preliminary_analysis?.consolidated ? (
												<>
													<TagRisk
														$result={riskCalc(file.preliminary_analysis.worst_score)}
														style={{
															textAlign: 'center',
															height: '22px'
														}}
														$isBrightBackground={riskCalc(
															file.preliminary_analysis.worst_score
														)}
													>
														<Tooltip
															title={I18n.get(
																`${
																	riskLevels[
																		riskCalc(file.preliminary_analysis.worst_score)
																	]
																}`
															)}
														>
															{riskCalc(file.preliminary_analysis.worst_score) === 5 ? (
																<Text ellipsis={true}>Serious risk</Text>
															) : (
																<Text ellipsis={true}>
																	{
																		riskLevels[
																			riskCalc(
																				file.preliminary_analysis.worst_score
																			)
																		]
																	}
																</Text>
															)}
														</Tooltip>
													</TagRisk>
												</>
											) : (
												<Text ellipsis={true}>{I18n.get('Not assigned')}</Text>
											)}
										</Col>
										<Col span={24}>
											<Label>{I18n.get('Workstation')}:</Label>
											<Text>{file.workstations?.name}</Text>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
				<ActionsWrapper>
					<ActionsForFile
						file={file}
						isDropDown={true}
						onEdit={onEditFile}
						onDelete={onDeleteFile}
						onDownload={onDownloadVideo}
						onRedirectPreliminary={onRedirectPreliminary}
						icon={<MoreOutlined style={{ color: 'black' }} />}
						onShowReportOptionsReports={onShowReportOptionsReports}
					/>
				</ActionsWrapper>
			</PlayerRowContainer>
			{modalStatus.isOpen && modalStatus.fileId === file.id && (
				<CustomModal
					centered
					width={600}
					footer={null}
					onCancel={handleCancel}
					open={modalStatus.isOpen}
					maskStyle={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
				>
					<Row justify="center" gutter={[0, 20]}>
						<Col>
							<Title style={{ marginTop: '2rem' }} level={5}>
								{file.original_name}
							</Title>
						</Col>
						<Player file={file} />
					</Row>
				</CustomModal>
			)}
		</Col>
	);
}
