import React from 'react';
import { Row, Col, Image } from 'antd';
import { I18n } from '@aws-amplify/core';

import { TitleContainer, KinebotLogoContainer } from './styles';
import { useApplicationContext } from '@/context/Application';
import { PROFILE_DEFAULT } from '@/utils/enum';
import { URL_LOGO } from '@/utils/constants';

export const Header = () => {
	const { organization, company } = useApplicationContext();
	const companyLogo = company?.url_logo;

	const companyLogoIsNotDefault = companyLogo !== PROFILE_DEFAULT.URL;
	const companyLogoVerified = companyLogoIsNotDefault ? companyLogo : undefined;

	const profileLogo = companyLogoVerified ?? organization?.url_logo;

	return (
		<Row>
			<Col span={24}>
				<Row align={'middle'} justify="space-between" gutter={[40, 0]}>
					<Col lg={5} xl={4}>
						<KinebotLogoContainer>
							<Image preview={{ visible: false, mask: false }} src={URL_LOGO} />
						</KinebotLogoContainer>
					</Col>
					<Col lg={14} xl={16}>
						<TitleContainer>
							<span>{I18n.get('Result')}</span>
							<span id="pea">{I18n.get('Ergonomic Workstation Analysis')}</span>
						</TitleContainer>
					</Col>
					<Col lg={5} xl={4}>
						<Row justify="end">
							<Col>
								<Image
									src={profileLogo}
									preview={false}
									width="auto"
									height="100%"
									style={{ maxHeight: '100px', borderRadius: '50%' }}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
