export enum QUERY_KEYS {
	GET_PLANS = 'get-plans',
	GET_PLAN = 'get-plan',
	GET_FILE = 'get-file',
	GET_USERS = 'get-users',
	GET_SECTORS = 'get-sectors',
	GET_CELLULES = 'get-cellules',
	GET_COMPANIES = 'get-companies',
	GET_FILES_LIST = 'get-file-list',
	GET_ACTIVITIES = 'get-activities',
	GET_RISK_RANGES = 'get-risk-ranges',
	GET_REBA_REPORT = 'get-reba-report',
	GET_RULA_REPORT = 'get-rula-report',
	GET_NIOSH_REPORT = 'get-niosh-report',
	GET_COMPANY_USERS = 'get-company-users',
	GET_PERMISSIONS = 'get-all-permissions',
	GET_KIM_PP_REPORT = 'get-kim-pp-report',
	GET_THUMBNAIL_URL = 'get_thumbnail_url',
	GET_KIM_MHO_REPORT = 'get-kim-mho-report',
	GET_FILE_HIERARCHY = 'get-gile-hierarchy',
	GET_USER_PERMISSIONS = 'get-user-permissions',
	GET_STRAIN_INDEX_REPORT = 'get-strain-index-report',
	GET_PLANS_CONTRACT_PLANS = 'get-plans-contract-plans',
	GET_COMPANY_CONTRACT_PLANS = 'get-company-contract-plans',
	GET_REBA_REPORTS_PAGINATED = 'get-reba-reports-paginated',
	GET_RULA_REPORTS_PAGINATED = 'get-rula-reports-paginated',
	GET_NIOSH_REPORTS_PAGINATED = 'get-niosh-reports-paginated',
	GET_COMPANIES_CONTRACT_PLANS = 'get-companies-contract-plans',
	GET_KIM_MHO_REPORTS_PAGINATED = 'get-kim-pp-reports-paginated',
	GET_KIM_PP_REPORTS_PAGINATED = 'get-kim-mho-reports-paginated',
	GET_COMPANIES_WITH_ORGANIZATION = 'get-company-with-organization',
	GET_ANGLE_TIME_REPORTS_PAGINATED = 'get-angle-time-reports-paginated',
	GET_ORGANIZATIONS_CONTRACT_PLANS = 'get-organizations-contract-plans',
	GET_CUSTOM_REPORT_RESULT_PAGINATED = 'get-custom-report-result-paginated',
	GET_STRAIN_INDEX_REPORTS_PAGINATED = 'get-strain-index-reports-paginated',
	GET_LIBERTY_MUTUAL_REPORTS_PAGINATED = 'get-liberty-mutual-reports-paginated',
	GET_ORGANIZATION_USERS_CONTRACT_PLANS = 'get-organization-users-contract-plans',
	GET_PRELIMINARY_ANALYZES_RESULT_PAGINATED = 'get-preliminary-analyzes-result-paginated'
}
