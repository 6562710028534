import React from 'react';
import { Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useContractPlansContext } from '../../context';
import { Header } from './Header';
import { Footer } from './Footer';
import { Table } from './Table';
import * as S from './styles';

interface ModalDeleteProps {
	open: boolean;
	onCancel: () => void;
}

export function UsersModal({ open, onCancel }: ModalDeleteProps) {
	const { queryUrl } = useContractPlansContext();

	return (
		<S.ModalStyled centered destroyOnClose open={open} onCancel={onCancel} footer={null}>
			<Row gutter={[0, 20]}>
				<Header>
					{I18n.get('Users')} "{queryUrl && queryUrl.get('name')}"
				</Header>
				<Table />
				<Footer onCancel={onCancel} />
			</Row>
		</S.ModalStyled>
	);
}
