import styled, { DefaultTheme } from 'styled-components';
import { Button, Col, Modal, Row, Table, Tag } from 'antd';

import { RiskScale } from '@/styles/enum';

type TagRiskProps = {
	$result: number;
	$isBrightBackground?: number;
};

export const TagRisk = styled(Tag)<TagRiskProps>`
	border-radius: 5px;
	background-color: ${({ $result, theme }) => setTagBackGroundColor(theme, $result)};
	span {
		color: ${({ $isBrightBackground, $result }) => ($isBrightBackground && $result !== 2 ? 'white' : 'black')};
	}
`;

export const TableContainer = styled(Col)`
	.ant-table {
		border-radius: 10px;
		.ant-table-thead {
			.ant-table-cell:first-child {
				border-radius: 10px 0 0 0;
			}
			.ant-table-cell:last-child {
				border-radius: 0 10px 0 0;
			}
		}
	}
`;

export const CustomButton = styled.div`
	&:hover {
		span {
			color: white;
		}
	}
`;

export const TableCustom = styled(Table)`
	.ant-table-selection-column {
		display: none;
	}
	.ant-table-content {
		table {
			border-spacing: 0 5px;
			background-color: #f0f2f5;
		}
		tr {
			background-color: #f0f2f5;
			border-radius: 10px;
		}
		.ant-table-cell {
			border-radius: 10px;
			background-color: #fff;
		}
		.ant-table-row-selected {
			.ant-table-cell {
				background-color: #eaeefd;
				border-radius: 10px;
				border: 2px solid #2f54eb;
			}
		}
	}
`;

export const ButtonList = styled(Button)<{ isList: boolean }>`
	border: none;
	box-shadow: none;
	margin: 10px 5px;
	background-color: transparent;

	span {
		font-size: 25px !important;
	}

	svg {
		fill: ${({ isList }) => (isList ? '#2F54EB' : 'black')};
	}

	&:hover {
		background-color: transparent;
	}
`;

export const ButtonMoreDetails = styled(Button)<{ isList: boolean }>`
	border: none;
	box-shadow: none;
	margin: 10px 5px;
	background-color: transparent;
	color: ${({ isList }) => (!isList ? '#2F54EB' : 'black')};

	span {
		font-size: 23px !important;
	}

	svg {
		fill: ${({ isList }) => (!isList ? '#2F54EB' : 'black')};
	}

	&:hover {
		background-color: transparent;
	}
`;

export const ModalCustom = styled(Modal)`
	.ant-modal-content {
		min-height: 400px;
		height: 40vh;
		box-shadow: none;
		border-radius: 32px;
	}
	.ant-modal-body {
		margin: 0;
		padding: 0;
	}
`;

export const GeneralActions = styled(Row)`
	overflow: hidden;
	border-radius: 5px;

	.ant-col {
		background-color: #ffffff;

		&:nth-child(2) {
			border-left: 2px solid #f0f2f5;
			border-right: 2px solid #f0f2f5;
		}

		svg {
			font-size: 22px;
		}
	}

	.ant-btn-dangerous {
		color: #e74150;
	}
`;

function setTagBackGroundColor(theme: DefaultTheme, $result: RiskScale) {
	if ($result === 0) {
		return theme.colors.riskScale[1];
	}
	return theme.colors.riskScale[$result] ?? theme.colors.riskScale[RiskScale.NOT_APPLICABLE];
}
