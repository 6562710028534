import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Row, Tooltip } from 'antd';

import { LibertyMutualTag, TagRisk } from './styles';
import { Text } from '@/components/Typography';
import { ScoreResult } from '@/types';
import { ErgonomicTools } from '../../types';

interface RatingTagProps {
	score: ScoreResult;
	reportName: ErgonomicTools;
}

export function RatingTag({ score, reportName }: Readonly<RatingTagProps>) {
	if (!score || typeof score.result !== 'number') {
		return <Text ellipsis={true}>Not assigned</Text>;
	}

	if (isLibertyMutualReport()) {
		return (
			<Row justify="space-around">
				<Col xs={8}>
					<LibertyMutualTag>
						<Tooltip title={I18n.get(score.text)}>
							<Text ellipsis={true}>{score.text}</Text>
						</Tooltip>
					</LibertyMutualTag>
				</Col>
				<Col xs={8}>
					<LibertyMutualTag>
						<Tooltip title={I18n.get(score.text)}>
							<Text ellipsis={true}>{score.text}</Text>
						</Tooltip>
					</LibertyMutualTag>
				</Col>
			</Row>
		);
	}

	function isLibertyMutualReport() {
		return reportName === ErgonomicTools['liberty-mutual'];
	}

	return (
		<Row justify="center">
			<Col xs={20}>
				<TagRisk style={{ width: '100%' }} $result={score.result} $isBrightBackground={score.result === 2}>
					<Tooltip title={I18n.get(score.text)}>
						<Text ellipsis={true}>{score.text}</Text>
					</Tooltip>
				</TagRisk>
			</Col>
		</Row>
	);
}
