import React from 'react';
import { Row, Col, Divider } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useHistory } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';

import { OrganizationUser } from '../../../hooks/useGetOrganizationUsers';
import { Column, EditButton, UserContainer } from './styles';
import { Loading } from '@/components/Spinner/styles';
import { Text } from '@/components/Typography';

interface ContentProps {
	isLoading?: boolean;
	data?: OrganizationUser[];
	isError?: boolean;
}

export function Content({ isLoading = false, data, isError = false }: Readonly<ContentProps>) {
	const history = useHistory();

	if (isLoading) {
		return <Loading />;
	}

	if (isError) {
		return <Text type="danger">{I18n.get('Failed to fetch information')}</Text>;
	}

	return (
		<Col span={24}>
			<Row gutter={[0, 10]}>
				{data?.map((user) => (
					<UserContainer span={24}>
						<Row align="middle" style={{ padding: '0 1rem' }}>
							<Col span={15}>
								<Text>{user.name}</Text>
							</Col>
							<Col span={1}>
								<Row>
									<Col>
										<Divider
											type="vertical"
											style={{ borderLeft: '1px solid #00000033', height: '100%' }}
										/>
									</Col>
								</Row>
							</Col>
							<Column span={6}>
								<Text>{user.role}</Text>
							</Column>
							<Col span={1}>
								<Row>
									<Col>
										<Divider
											type="vertical"
											style={{ borderLeft: '1px solid #00000033', height: '100%' }}
										/>
									</Col>
								</Row>
							</Col>
							<Column span={1}>
								<EditButton
									ghost
									type="primary"
									onClick={() => history.push(`/users?name=${user.name}`)}
									icon={<EditOutlined style={{ fontSize: '20px' }} />}
								/>
							</Column>
						</Row>
					</UserContainer>
				))}
			</Row>
		</Col>
	);
}
