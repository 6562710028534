import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as Icons from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import { Modal, Row, Table } from 'antd';

import { FilesListResponse } from '@/hooks';
import { ListWithoutPlayer, ListWithoutPlayerProps } from './ListWithoutPlayer';
import { useApplicationContext } from '@/context/Application';
import { useDownloadVideo } from '@/hooks/useDownloadVideo';
import { ErgonomicToolsModal } from './ErgonomicToolsModal';
import { PaginationCustom } from '@/components/Pagination';
import { FilterRequest } from '../../../../views/ListReport/types';
import { useDeleteFile } from '@/hooks/useDeleteFile';
import { useListFiles } from '@/hooks/useListFiles';
import { ListWithPlayer } from './ListWithPlayer';
import { EditFileModal } from './EditFileModal';
import { TableContainer } from '../styles';

type DownloadVideo = {
	id: string;
	original_name: string;
};

type EditFileModalOpen = {
	isOpen: boolean;
	fileId?: string;
};

type SelectedReport = {
	visibleModal: boolean;
	file: FilesListResponse | null;
};

interface ListProps {
	isList: boolean;
	selectedRowKeys: string[];
	onSelectRowKeys: (rows: string[]) => void;
}

export function List({ isList, selectedRowKeys, onSelectRowKeys }: Readonly<ListProps>) {
	const history = useHistory();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const { organization, company } = useApplicationContext();

	const queryParams = convertURLParamToObject(searchParams);
	const page = queryParams.page ? +queryParams.page : 1;
	const limit = queryParams.rows ? +queryParams.rows : 10;
	const offset = page === 0 ? 0 : page - 1;

	const {
		data: files,
		isLoading,
		isFetching
	} = useListFiles({
		organization_id: organization?.id,
		company_id: company?.id,
		filter: {
			...queryParams,
			limit,
			offset
		}
	});
	const { mutateAsync: downloadVideo } = useDownloadVideo();
	const { mutateAsync: deleteFile, isLoading: deletingFile } = useDeleteFile();

	const [selectedReport, setSelectedReport] = useState<SelectedReport>({
		file: null,
		visibleModal: false
	});

	useEffect(() => {
		onSelectRowKeys([]);
	}, [files]);

	const [editFileModal, setEditFileModal] = useState<EditFileModalOpen>({
		isOpen: false,
		fileId: ''
	});

	async function handleDownloadVideo(file: DownloadVideo) {
		const data = {
			file_id: file.id,
			company_id: company.id,
			organization_id: organization.id,
			original_name: file.original_name
		};
		await downloadVideo(data);
	}

	async function handleDeleteFile(fileId: string) {
		Modal.confirm({
			title: I18n.get('Heads up! Want to delete this file?'),
			icon: <Icons.ExclamationCircleOutlined />,
			okText: I18n.get('Confirm'),
			cancelText: I18n.get('Cancel'),
			okType: 'danger',
			onOk: async () => {
				const body = {
					file_id: fileId,
					company_id: company?.id,
					organization_id: organization?.id
				};

				await deleteFile(body);
			}
		});
	}

	function handleEditFile(fileId?: string, event?: React.MouseEvent<HTMLElement, MouseEvent>): void {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		setEditFileModal({
			fileId,
			isOpen: !editFileModal.isOpen
		});
	}

	function handleRedirectPreliminary(fileId: string): void {
		const win = window.open(`/reporting/preliminary-ergonomic-analysis/${fileId}`, '_blank');
		win?.focus();
	}

	function handleSelectRow(id: string) {
		const selectedRows = [...selectedRowKeys];

		if (selectedRows.indexOf(id) >= 0) {
			selectedRows.splice(selectedRows.indexOf(id), 1);
		} else {
			selectedRows.push(id);
		}
		handleSelectRowKeys(selectedRows);
	}

	function handleSelectRowKeys(selectedRows: string[]) {
		onSelectRowKeys(selectedRows);
	}

	function convertURLParamToObject(searchParams: URLSearchParams): FilterRequest {
		const params = Object.fromEntries(searchParams.entries());
		return params as FilterRequest;
	}

	function handleRowsPerPageChange(rows: number) {
		searchParams.set('page', `${1}`);
		searchParams.set('rows', `${rows ?? 10}`);

		history.push({
			search: searchParams.toString()
		});
	}

	function handlePageChange(page: number): void {
		searchParams.set('page', `${page ?? 1}`);

		history.push({
			search: searchParams.toString()
		});
	}

	function handleOpenCreateReportModal(file: FilesListResponse) {
		setSelectedReport((current) => ({
			...current,
			file: file,
			visibleModal: true
		}));
	}

	function handleCloseReportsModal() {
		setSelectedReport((current) => ({
			...current,
			file: null,
			visibleModal: false
		}));
	}

	const properties: ListWithoutPlayerProps = {
		files: files.rows,
		onEditFile: handleEditFile,
		onSelectRow: handleSelectRow,
		selectedRows: selectedRowKeys,
		onDeleteFile: handleDeleteFile,
		onDownloadVideo: handleDownloadVideo,
		loading: deletingFile || isLoading || isFetching,
		onRedirectPreliminary: handleRedirectPreliminary,
		onShowReportOptionsReports: handleOpenCreateReportModal
	};

	if (isLoading || isFetching) {
		return <Table loading />;
	}

	return (
		<Row>
			<TableContainer span={24}>
				{isList ? (
					<ListWithoutPlayer {...properties} />
				) : (
					<Row gutter={[0, 10]}>
						{files?.rows?.map((file) => (
							<ListWithPlayer file={file} onSelectRowKey={handleSelectRowKeys} {...properties} />
						))}
					</Row>
				)}
				<PaginationCustom
					current={page}
					pageSize={limit}
					total={files.count}
					onChange={handlePageChange}
					onRowsPerPageChange={handleRowsPerPageChange}
				/>
			</TableContainer>
			{editFileModal.isOpen && (
				<EditFileModal
					centered
					width={550}
					footer={null}
					open={editFileModal.isOpen}
					fileId={editFileModal.fileId}
					onCancel={(event) => handleEditFile(undefined, event)}
				/>
			)}
			{selectedReport.visibleModal && selectedReport.file && (
				<ErgonomicToolsModal
					file={selectedReport.file}
					onClose={handleCloseReportsModal}
					visible={selectedReport.visibleModal}
				/>
			)}
		</Row>
	);
}
