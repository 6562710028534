import React from 'react';
import { I18n } from '@aws-amplify/core';

import { EditCompanyProvider } from './context';
import { Content } from './Content';
import { Header } from './Header';
import { Footer } from './Footer';

import * as S from '../styles';

interface EditCompanyProps {
	open: boolean;
	companyId: string;
	onCancel: () => void;
	organizationId?: string;
}

export function EditCompanyModal({ open, onCancel, companyId, organizationId }: Readonly<EditCompanyProps>) {
	return (
		<S.CustomModal centered destroyOnClose open={open} onCancel={onCancel} footer={null}>
			<EditCompanyProvider onCancel={onCancel} companyId={companyId} organizationId={organizationId}>
				<S.Container gutter={[0, 15]}>
					<Header>{I18n.get('Edit company')}</Header>
					<S.ContentWrapper>
						<Content />
					</S.ContentWrapper>
					<S.FooterWrapper>
						<Footer />
					</S.FooterWrapper>
				</S.Container>
			</EditCompanyProvider>
		</S.CustomModal>
	);
}
