import { Card, Col, Modal, Row } from 'antd';
import styled from 'styled-components';

export const ContainerChart = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 300px;
`;

export const Item = styled.label`
	display: block;
`;

export const ItemValue = styled(Item)`
	padding-top: 10px;
	font-weight: bold;
`;

export const ModalCard = styled(Card)`
	text-align: center;
	margin-top: 10px;
	border-radius: 17px;
	min-width: 205px;
	height: 115px;
	background: #f5f5f5;
	color: #262626;
	margin-bottom: 0px;

	svg {
		fill: #262626;
		font-size: 25px;
	}
	.message-card {
		font-size: 10px;
		color: grey;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		max-width: 160px;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
	}

	.title-card {
		padding: 0;
		overflow: hidden;
		max-width: 160px;
		margin: 8px 0 0 0;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		text-overflow: ellipsis;
		-webkit-box-orient: vertical;
	}

	.custom-icon {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	.custom-icon-hovered {
		display: none;
	}

	@media (min-height: 800px) {
		margin-bottom: 20px;
		min-width: 211px;
		height: 115px;
		svg {
			fill: #262626;
			font-size: 1.5rem;
		}
		.message-card {
			font-size: 13px;
			color: grey;
		}
		.title-card {
			margin: 10px 0 0 0;
			padding: 0;
			font-weight: 600;
		}
	}
	&:hover {
		.custom-icon {
			display: none;
		}
		.custom-icon-hovered {
			display: flex;
			flex-direction: row;
			justify-content: center;
		}
		background: #2f54eb;
		color: #fff;
		cursor: pointer;
		svg {
			fill: #fff;
		}
		.message-card {
			color: #fff;
		}
	}
`;

export const ModalBorder = styled(Modal)`
	.ant-modal-content {
		border-radius: 30px;
		.title-modal {
			font-size: 35px;
			text-align: center;
		}

		@media (min-height: 800px) {
			padding-top: 25px;
			.title-modal {
				font-size: 35px;
				text-align: center;
				margin: 0 0 10px 0;
			}
			.paragraph-modal {
				margin: 0 0 45px 0;
			}
		}
	}
`;

export const ModalColumn = styled(Col)`
	overflow-y: scroll;
	overflow-x: hidden;
	height: 395px;

	@media (min-height: 800px) {
		height: 500px;
	}

	::-webkit-scrollbar {
		width: 4px;
	}

	::-webkit-scrollbar-track {
		border-radius: 2px;
	}

	::-webkit-scrollbar-thumb {
		background: #4156f2;
		border-radius: 2px;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
`;

export const ErgonomicToolCol = styled(Col)`
	text-align: center;
`;

export const ErgonomicToolRow = styled(Row)`
	.ergonomic-tool-col {
		:not(:last-child) {
			border-right: solid 2px #f5f5f5;
		}
	}
`;
